<template>
	<div class="fund-detail-page">
		
		<section class="layer fund-detail">
			<div class="layer-content">
				<p class="name">{{fund.name}}</p>
				<p class="code">{{fund.code}}</p>
				<van-row class="item-value">
					<van-col span="9" class="ratio">{{fund.ratio}}</van-col>
					<van-col span="6" class="net text-center">{{fund.net}}</van-col>
					<van-col span="9" class="surplus-total text-right">{{fund.surplus_total}}</van-col>
				</van-row>
				<van-row class="item-name">
					<van-col span="9">{{translate('increase_since_establishment')}}</van-col>
					<van-col span="6" class="text-center">{{translate('net_unit_value_hk')}}</van-col>
					<van-col span="9" class="text-right">{{translate('remaining_amount')}}</van-col>
				</van-row>
			</div>
		</section>
		
		<section class="layer history-net">
			<h4 class="layer-title">{{translate('historical_net_worth')}}</h4>
			<div class="layer-content">
				<van-row class="table-title">
					<van-col span="10">{{translate('date')}}</van-col>
					<van-col span="4" class="text-center">{{translate('net_unit_value')}}</van-col>
					<van-col span="10" class="text-right">{{translate('daily_rise_and_fall')}}</van-col>
				</van-row>
				<van-list
					v-model="loading"
					:finished="finished"
					:finished-text="translate('nets_no_more_records_yet')"
					@load="onLoadListData"
				>
					<van-row v-for="(item, key) in lists" :key="key" class="table-item">
						<van-col span="10" class="date">{{item.date}}</van-col>
						<van-col span="4" class="value text-center">{{item.value}}</van-col>
						<van-col span="10" class="ratio text-right color-negative" v-if="item.ratio < 0">{{item.ratio}}%</van-col>
						<van-col span="10" class="ratio text-right color-zero" v-if="item.ratio == 0">{{item.ratio}}%</van-col>
						<van-col span="10" class="ratio text-right color-just" v-if="item.ratio > 0">{{item.ratio}}%</van-col>
					</van-row>

				</van-list>

					
			</div>
		</section>

	</div>
</template>

<script>

	export default {
		name: 'fund_nets',
		data() {
			return {

				fund: {
					name: "",
					code: "",
					ratio: "",
					net: "",
					surplus_total: "",
				},

				lists: [],

				finished: false,
				loading: false,

				formItem: {
					id: 0,
					page: 1,
					limit: 20,
				},
			}
		},
		mounted() {
			this.formItem.id = this.$route.query.id
		},

		methods: {

			onLoadListData() {

				this.$axios({
					method: 'post',
					url: 'fund_fund/nets', 
					data: this.formItem
				}).then ((res) => {

					this.loading = false
					
					if (res.success) {
						if (res.data.lists.length != 0) {
							this.lists = this.lists.concat(res.data.lists)

							this.fund.name = res.data.fund.name
							this.fund.code = res.data.fund.code
							this.fund.ratio = res.data.fund.ratio
							this.fund.net = res.data.fund.net
							this.fund.surplus_total = res.data.fund.surplus_total
							
							if (res.data.length < this.formItem.limit) {
								this.finished = true
							} else {
								this.formItem.page ++
							}
						} else {
							this.finished = true
						}
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}

				})
			}
		},
	}
</script>